import React from 'react';
import Login from '../components/Login'
import Layout from '../components/layout';

const Index = () => {
  return (
    <Layout>
      <Login />
    </Layout>
  )
};

export default Index;